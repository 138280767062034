import React from "react"
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews"
import { Link, PageProps } from "gatsby"

const NotFound = (props: PageProps) => (
  <div
    className="flex flex-col items-center justify-center h-screen min-h-full"
    data-intersection-targets-root
  >
    <h1 className="text-lg">
      Page not found. <Link to="/">Go home</Link>.
    </h1>
  </div>
)

export default withPrismicUnpublishedPreview(NotFound)
